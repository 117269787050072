<template lang="pug">
  v-list-item(dense, @click, v-model="$user.ok && (user.ranking.userId || user.userProfile.userId) == $user.id")
    .my-auto
      CardIcon.my-2.mr-4(:id="userCard.cardId", :afterTraining="userCard.defaultImage == 'special_training'")
    
    v-list-item-content
      v-list-item-title
        span.v-list-item__subtitle # {{user.ranking.rank || 0}} 
        span {{user.ranking.name || user.user.userGamedata.name}}
        v-list-item-action-text.mx-2(v-if="user.friendlyName") {{user.friendlyName}}

      v-list-item-subtitle(style="max-width: 192px")
        ProfileHonors(:userProfileHonors="user.ranking.userProfileHonors || user.userProfileHonors || []", size=20)

    v-list-item-action.ml-2(v-if="user.ranking.score===undefined")
      v-list-item-action-text ...

    v-list-item-action.ml-2(v-else)
      //- v-list-item-title.align-self-end(v-if="user.ranking.rank") # {{user.ranking.rank}}
      v-list-item-action-text(v-if="user.ranking.score!==undefined") {{user.ranking.score}} P
        

    //- p {{user}}
</template>

<script>
import ProfileHonors from '@/components/ProfileHonors';
import CardIcon from '@/components/CardIcon';

export default {
  name: 'User',

  props: {
    user: {},
  },

  components: { ProfileHonors, CardIcon },

  computed: {
    userCard() {
      return this.user.ranking.userCard || this.user.userCards.find(card => card.cardId == this.user.userDecks[0].leader);
    }
  },
};
</script>
