<template lang="pug">
  div
    .d-flex
      div(:class="difficultyClass", v-if="musicDifficulty || difficulty")
        span(v-if="!id && !level") {{difficulty}}

        template(v-else)
          span {{musicDifficulty.playLevel}}
          sup(v-if="musicDifficulty.playLevelAdjust > 1.50") +
          sup(v-if="musicDifficulty.playLevelAdjust > 0.50") +
          sup(v-if="musicDifficulty.playLevelAdjust < -0.50") -
          sup(v-if="musicDifficulty.playLevelAdjust < -1.50") -

      div(:class="rankClass", v-if="rankComputed !== undefined")
        v-icon(v-if="!rank && musicDifficultyStatus != 'available'", x-small) mdi-lock
        span(v-else) {{rankComputed}}

</template>

<script>
export default {
  name: 'MusicDifficulty',

  props: ['id', 'difficulty', 'level', 'rank', 'disable'],

  computed: {
    musicDifficulty() {
      return this.id ? this.$db.musicDifficulties[this.id][this.difficulty] :
        this.level ? { playLevel: this.level } :
          null;
    },

    rankComputed() {
      return this.rank || this.$profile.userMusics && this.id && this.$profile.$.userMusics[this.id].userMusicDifficultyStatuses[this.difficulty].rank;
    },

    musicDifficultyStatus() {
      return this.$profile.userMusics && this.id && this.$profile.userMusics.find(userMusic => (
        userMusic.musicId == this.id
      ))?.userMusicDifficultyStatuses.find(userMusicDifficultyStatus => (
        userMusicDifficultyStatus.musicDifficulty == this.difficulty
      )).musicDifficultyStatus;
    },

    difficultyClass() {
      let cls = {};
      cls.difficulty = !this.disable;
      cls.rank = !(this.id && this.$profile.userMusicResults);
      cls.disable = !!this.disable;
      cls[this.difficulty] = true;
      return cls;
    },

    rankClass() {
      let cls = {};
      cls.difficulty = !(this.musicDifficulty || this.difficulty);
      cls.rank = !(this.id && !this.$profile.userMusicResults);
      cls[this.difficulty] = true;
      cls[this.rankComputed] = true;
      return cls;
    },

    color() {
      return {
        easy: '#81C784',
        normal: '#64B5F6',
        hard: '#FFB74D',
        expert: '#E57373',
        master: '#BA68C8',
      }[this.difficulty];
    },
  },
};
</script>


<style lang="scss" scoped>
.difficulty,
.rank,
.disable {
  white-space: nowrap;
  text-align: center;
}

.difficulty,
.disable {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  width: 50%;
}

.theme--dark {
  .difficulty {
    color: #00000099;
    font-weight: 550;
  }
}
.theme--light {
  .difficulty {
    color: #ffffff;
    font-weight: 550;
  }
}

.rank {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  width: 50%;
}

.difficulty.rank,
.disable.rank {
  width: 100%;
}

.P {
  font-weight: 550;
  border: 1px solid #f0f0f0 !important;
  background-image: linear-gradient(#f06292, #64b5f6);
}

.F {
  font-weight: 550;
  border: 1px solid #f06292 !important;
  background-color: #f06292;
}
.theme--dark {
  .P {
    color: #ffffff;
    .v-icon {
      color: #ffffff;
    }
  }
  .F {
    color: #00000099;
    .v-icon {
      color: #00000099;
    }
  }
  .C {
    color: #b0b0b0;
    .v-icon {
      color: #b0b0b0;
    }
  }
  .v-icon {
    color: #b0b0b0;
  }
}
.theme--light {
  .P {
    color: #ffffff;
    .v-icon {
      color: #ffffff;
    }
  }
  .F {
    color: #fffffff0;
    .v-icon {
      color: #fffffff0;
    }
  }
  .C {
    color: #777777;
    .v-icon {
      color: #777777;
    }
  }
  .v-icon {
    color: #777777;
  }
}

.easy {
  border: 1px solid #81c784;
}
.difficulty.easy {
  background-color: #81c784;
}
.disable.easy {
  color: #81c784;
}

.normal {
  border: 1px solid #64b5f6;
}
.difficulty.normal {
  background-color: #64b5f6;
}
.disable.normal {
  color: #64b5f6;
}

.hard {
  border: 1px solid #ffb74d;
}
.difficulty.hard {
  background-color: #ffb74d;
}
.disable.hard {
  color: #ffb74d;
}

.expert {
  border: 1px solid #e57373;
}
.difficulty.expert {
  background-color: #e57373;
}
.disable.expert {
  color: #e57373;
}

.master {
  border: 1px solid #ba68c8;
}
.difficulty.master {
  background-color: #ba68c8;
}
.disable.master {
  color: #ba68c8;
}
</style>