export default [
  {
    name: 'musics.totalNoteCount',
    function: 'musicDifficulty.totalNoteCount',
    order: -1,
    format: x => x,
    difficulty: true,
  },
  {
    name: 'musics.playLevel',
    function: 'musicDifficulty.playLevel',
    order: -1,
    format: x => x,
    difficulty: true,
    hidden: true,
  },
  {
    name: 'musics.levelAdjust',
    function: 'musicDifficulty.playLevel+(musicDifficulty.playLevelAdjust||0)',
    order: -1,
    format: x => x.toFixed(1),
    difficulty: true,
  },
  {
    name: 'musics.fullComboAdjust',
    function: 'musicDifficulty.playLevel+(musicDifficulty.fullComboAdjust||0)',
    order: -1,
    format: x => x.toFixed(1),
    difficulty: true,
  },
  {
    name: 'musics.fullPerfectAdjust',
    function: 'musicDifficulty.playLevel+(musicDifficulty.fullPerfectAdjust||0)',
    order: -1,
    format: x => x.toFixed(1),
    difficulty: true,
  },
  {
    name: 'default',
    function: undefined,
    order: 1,
    format: x => x,
    difficulty: false,
    hidden: true,
  },
  {
    name: 'musics.id',
    function: 'music.id',
    order: 1,
    format: x => x,
    difficulty: false,
  },
  {
    name: 'musics.musicTitle',
    function: 'music.title',
    order: 1,
    format: x => x,
    difficulty: false,
    hidden: true,
  },
  {
    name: 'musics.publishedAt',
    function: 'music.publishedAt',
    order: -1,
    format: x => new Date(x).toLocaleDateString(),
    difficulty: false,
  },
  {
    name: 'musics.hot',
    function: 'music.hot',
    order: -1,
    format: x => x && x.toFixed(0),
    difficulty: false,
  },
  {
    name: 'musics.duration',
    function: 'music.duration',
    order: -1,
    format: x => `${Math.floor(x / 60)}:${('0' + Math.floor(x % 60)).slice(-2)}`,
    difficulty: false,
  },
  {
    name: 'musics.bpm',
    function: 'music.bpm',
    order: -1,
    format: (x, context) => `${context.music.bpms ? '~ ' : ''}${x}`,
    difficulty: false,
  },
];
