<template lang="pug">
  v-list-item(v-if="!music || music.publishedAt > new Date().valueOf()", @click)
    v-list-item-subtitle {{$t('musics.notAvailable')}}

  v-list-item(v-else, :to=" { path: `/music/${id}`, query: $route.query }", :replace="$vuetify.breakpoint.mdAndUp && $route.name == 'music'")
    .d-block(style="width: 100%")
      v-lazy(min-height=56)
        .d-flex
          v-list-item-avatar(tile)
            v-img(:src="`${$sekai.assetHost}/thumbnail/music_jacket/${music.assetbundleName}.webp`")
          v-list-item-content
            v-list-item-title.d-flex
              v-list-item-subtitle.pr-2(style="flex: 0 1 auto", v-if="sortFunction && !sort.difficulty && !(defaultSortFunction && defaultSortFunction.hidden)")
                template(v-if="defaultSortFunction")
                  | {{defaultSortFunction.format(sortFunction(music), {music: music})}}
                template(v-else)
                  | {{sortFunction(music)}}
              span {{music.title}}
            v-list-item-subtitle.d-flex
              template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
                .pr-2(v-if="i")
                div(style="width: calc(20% - 3.2px)")
                  MusicDifficulty(:id="id", :difficulty="difficulty")

      .d-flex.pb-2(v-if="sortFunction && sort.difficulty && !(defaultSortFunction && defaultSortFunction.hidden)")
        div.mr-4.my-auto(style="width: 40px")
          v-list-item-title.text-wrap.text-center
            template(v-if="defaultSortFunction")
              | {{$t(defaultSortFunction.name)}}

        v-list-item-content.py-0
          .d-flex
            template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
              .pr-2(v-if="i")
              v-list-item-action-text.text-center(style="width: calc(20% - 3.2px)")
                template(v-if="defaultSortFunction")
                  | {{defaultSortFunction.format(sortFunction(music, difficulty), {music: music, musicDifficulty: $db.musicDifficulties[music.id][difficulty || sort.difficulty]})}}
                template(v-else)
                  | {{sortFunction(music, difficulty)}}
</template>

<script>
import MusicDifficulty from '@/components/MusicDifficulty';
import defaultSortFunctions from './defaultSortFunctions';

export default {
  name: 'Music',

  props: ['id'],

  components: { MusicDifficulty },

  computed: {
    music() {
      return this.$db.musics[this.id];
    },

    sort() {
      let sort = {};
      for (let key in this.$route.query) {
        if (!key.startsWith('sort.')) continue;
        sort[key.slice(5)] = this.$route.query[key];
      }
      return sort;
    },

    sortFunction() {
      if (this.sort.function) {
        return (music, difficulty) => {
          // eslint-disable-next-line
          let musicDifficulty = this.$db.musicDifficulties[music.id][difficulty || this.sort.difficulty];

          return eval(this.sort.function);
        };
      }

      return undefined;
    },

    defaultSortFunction() {
      return defaultSortFunctions.find(defaultSortFunction => defaultSortFunction.function == this.sort.function);
    },
  }
};
</script>
