<template lang="pug">
  div(style="max-width: 720px; margin: auto", v-if="event")
    h1 {{event.name}}

    a(:href="`${$sekai.assetHost}/event/${event.assetbundleName}/screen/bg.png`", target="_blank")
      v-img(contain, :aspect-ratio="2048/1261"
        :src="`${$sekai.assetHost}/event/${event.assetbundleName}/screen/bg.webp`",
        @click="")
    .py-2

    Divider
    v-list.py-0(dense)
      Audio(flat, color="primary", downloadable, 
        :src="`${$sekai.assetHost}/event/${event.assetbundleName}/bgm/${event.assetbundleName}_top.mp3`")

      template(v-if="$db.eventMusics[id]")
        Divider(inset=16)
        Music(:id="$db.eventMusics[id].musicId")

    Divider
    .py-2

    h3 {{$t('events.eventInformation')}}

    Divider
    v-list.py-0(dense)
      v-list-item
        v-list-item-content
          v-list-item-title {{$t('events.id')}}
        v-list-item-action
          v-list-item-action-text {{event.id}}

      Divider(inset=16)
      v-list-item
        v-list-item-content
          v-list-item-title {{$t('events.name')}}
        v-list-item-action
          v-list-item-action-text {{event.name}}

      Divider(inset=16)
      v-list-item
        v-list-item-content
          v-list-item-title {{$t('events.eventType')}}
        v-list-item-action
          v-list-item-action-text {{event.eventType}}

      Divider(inset=16)
      v-list-item
        v-list-item-content
          v-list-item-title {{$t('events.startAt')}}
        v-list-item-action
          v-list-item-action-text {{new Date(event.startAt).toLocaleString()}}

      Divider(inset=16)
      v-list-item
        v-list-item-content
          v-list-item-title {{$t('events.aggregateAt')}}
        v-list-item-action
          v-list-item-action-text {{new Date(event.aggregateAt).toLocaleString()}}

    Divider
    .py-2

    h3 {{$t('events.eventCards')}}

    .d-flex.px-4.py-1
      template(v-for="eventCardId, i in eventCardIds")
        .pr-2(v-if="i")
        CardIcon(:id="eventCardId", size=40)

    .py-2

    h3 {{$t('events.eventRankings')}}

    //- v-tabs(v-model="tab", fixed-tabs)
    //-   v-tab.px-0 {{$t('events.users')}}
    //-   v-tab.px-0 {{$t('events.tops')}}
    //-   v-tab.px-0 {{$t('events.borders')}}

    Divider
    v-list.py-0(dense)
      template(v-for="ranking, i in rankings")
        Divider(inset=72, v-if="i")
        template(v-if="ranking.border")
          v-list-item(@click)
            div(style="width: 56px")
            v-list-item-content
              v-list-item-subtitle # {{ranking.ranking.rank}}
            v-list-item-action
              v-list-item-action-text {{ranking.ranking.score}} P
        template(v-else)
          User(:user="ranking")
      template(v-if="rankings.length == 0")
        v-list-item(@click="loadRankings()", :disable="rankingsMagic != 0")
          v-list-item-content
            v-list-item-title {{$t('events.loadRanking')}}

    Divider
    .py-2

    WIP
      Divider(inset=16)
      v-list-item
        v-list-item-subtitle イベントポイントの予測（<a href="https://3-3.dev/" target="_blank">3-3.dev</a> から）

      Divider(inset=16)
      v-list-item
        v-list-item-subtitle イベントランキングのランクのカスタマイズ

    //- p(style="overflow: hidden") {{event}}

</template>

<script>
import * as idb from 'idb-keyval';

import Music from '@/views/Musics/Music';
import CardIcon from '@/components/CardIcon';
import User from './User';

export default {
  name: 'Event',

  components: { Music, CardIcon, User },

  data() {
    return {
      tab: 0,
      rankings: [],
      rankingsMagic: 0,
    };
  },

  computed: {
    id() {
      return this.$route.params.id;
    },
    event() {
      return this.$db.events[this.id];
    },
    eventCardIds() {
      return Object.values(this.$db.eventCards)
        .filter(eventCard => eventCard.eventId == this.id)
        .map(eventCard => eventCard.cardId);
    },
    usersSortFunction() {
      return (a, b) => {
        if (a.ranking.score === undefined || b.ranking.score === undefined) {
          return 0;
        }
        if ((a.ranking.score || 0) < (b.ranking.score || 0)) {
          return 1;
        }
        if ((a.ranking.score || 0) > (b.ranking.score || 0)) {
          return -1;
        }
        return 0;
      };
    },
  },

  methods: {
    loadRankings() {
      if (!this.event) {
        return;
      }

      let id = this.id;
      let magic = Math.random();
      this.rankingsMagic = magic;
      this.rankings = [];
      idb.values().then(users => {
        users.filter(user => user.user && user.userProfile).forEach(user => {
          if (id != this.id || magic != this.rankingsMagic) {
            return;
          }
          user.ranking = {};
          this.$sekai.api(`/api/user/{user_id}/event/${this.id}/ranking?targetUserId=${user.userProfile.userId}`).then(response => {
            let ranking = response.rankings[0];
            if (!ranking) {
              user.ranking = {
                score: 0,
              };
            } else if (ranking.userId != user.userProfile.userId) {
              user.ranking = {
                rank: ranking.rank,
                score: ranking.score,
              };
            } else {
              user.ranking = ranking;
            }
            this.rankings.push(user);
            this.rankings.sort(this.usersSortFunction);
          });
        });
      });

      [
        100, 200, 500,
        1000, 2000, 5000,
        10000, 20000, 50000,
        100000, 200000, 500000,
        1000000, 2000000, 5000000,
      ].forEach(rank => {
        this.$sekai.api(`/api/user/{user_id}/event/${this.id}/ranking?targetRank=${rank}`).then(response => {
          response.rankings.forEach(ranking => {
            if (id != this.id || magic != this.rankingsMagic) {
              return;
            }
            this.rankings.push({
              border: true,
              ranking: ranking,
            });
            this.rankings.sort(this.usersSortFunction);
          });
        });
      });
    },

    autoLoad() {
      if (this.$settings.get('rankingAutoLoad')) {
        this.loadRankings();
      }
    },
  },

  mounted() {
    this.autoLoad();
  },

  watch: {
    id() {
      this.autoLoad();
    }
  }
};
</script>
